import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function KontaktPage() {
  return (
    <Layout>
      <SEO
        keywords={[`kontakt`]}
        title="Kontakt"
      />
      <section>
        <div className="">
          <p>Wenn Sie noch Fragen haben oder mehr Informationen wünschen, sind wir erreichbar unter:</p>
            <a href="mailto:info@quergedacht.nrw" className="text-xl">info@quergedacht.nrw</a>
          
          <p className="mt-16">Möchten Sie uns beauftragen, schicken Sie uns gerne eine Mail an:</p>
          <a href="mailto:fallanfrage@quergedacht.nrw" className="text-xl">fallanfrage@quergedacht.nrw</a>
        </div>
      </section>
    </Layout>
  );
}

export default KontaktPage;
